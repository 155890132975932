<!--
 * @Author: dongjia
 * @Date: 2021-06-24 09:49:01
 * @LastEditTime: 2021-07-06 14:05:25
 * @LastEditors: aleaner
 * @Description: 转账记录查看详情弹窗
 * @FilePath: \organization-admin\components\Member\TransferDetailDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="查看" :visible.sync="openDialog" width="540px" class="dialog-vertical">
    <div class="dialog-content" v-loading="loading">
      <div v-if="detailForm">
        <div class="form-item">
          <p class="form-label">提交人：</p>
          <div class="form-content">{{detailForm.user_nickname | placeholder}}</div>
        </div>
        <div class="form-item">
          <p class="form-label">提交职务：</p>
          <div class="form-content">{{detailForm.position_name | placeholder}}</div>
        </div>
        <div class="form-item">
          <p class="form-label">说明：</p>
          <div class="form-content">
            {{detailForm.remark | placeholder}}</div>
        </div>
        <div class="form-item">
          <p class="form-label">图片：</p>
          <div class="form-content">
            <el-image class="form-image cursor-pointer" :src="fixImageUrl(item)"
              v-for="(item, index) in detailForm.images" :key="index" fit="cover"
              @click="$previewImage([item])">
            </el-image>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer">
      <el-button size="small" @click="openDialog = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { transferDetail } from "../../api/member/member-transfer";

export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    id: {
      type: [Number, String],
      default: 0,
    },
  },
  watch: {
    id(val) {
      if (val) {
        this.getTransferDetail();
      }
    },
  },
  data() {
    return {
      loading: false,
      detailForm: null,
    };
  },
  methods: {
    // 获取转账详情
    getTransferDetail() {
      this.loading = true;
      transferDetail({ id: this.id })
        .then((res) => {
          this.detailForm = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss"scoped>
.dialog-content {
  min-height: 200px;
  .form-item + .form-item {
    margin-top: 16px;
  }
  .form-item {
    display: flex;
    align-items: flex-start;
    .form-label {
      flex-shrink: 0;
      width: 80px;
      text-align: end;
      line-height: 20px;
    }
    .form-content {
      width: 250px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      line-height: 20px;
      .form-image {
        width: 70px;
        height: 70px;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
