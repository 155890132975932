/*
 * @Author: dongjia
 * @Date: 2021-06-23 17:47:17
 * @LastEditTime: 2022-01-04 15:29:24
 * @LastEditors: aleaner
 * @Description: 会员转账记录接口
 * @FilePath: \organization-admin\api\member\member-transfer.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";
import fetchFile from "@/base/utils/download";

// 获取转账记录列表
export const transferList = data => {
  return api({
    url: "/admin/cyc/credential/index",
    method: "post",
    data,
    notCancel: true
  });
};

// 查看转账记录详情
export const transferDetail = data => {
  return api({
    url: "/admin/cyc/credential/detail",
    method: "post",
    data,
    notCancel: true
  });
};

//转账记录列表导出
export const documentCredentialList = data => {
  return fetchFile({
    url: "/admin/cyc/credential/export",
    method: "post",
    data,
    download: true
  });
};